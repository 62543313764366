import '../../fonts/styles.css'; // Import local fonts

import { createGlobalStyle } from 'styled-components';
import { rem, getContrast, lighten } from 'polished';
import { breakpoint, headingSizes, buttonVariants } from '../../theme';

export default createGlobalStyle`
    *, *::before, *::after {
      box-sizing: inherit;
    }

    html {
      box-sizing: border-box;
      font-size: ${({ theme }) => theme.typography.global.fontSize};
    }

    body {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-rendering: ${({ theme }) => theme.typography.global.rendering};
      color: ${({ theme }) => theme.palette.text.primary};
      background-color: ${({ theme }) => theme.palette.background.body};
      font-family: ${({ theme }) => theme.typography.fonts.primary};
      line-height: ${({ theme }) => theme.typography.global.lineHeight};
      font-size: ${({ theme }) => theme.typography.paragraph.fontSize};
      font-weight: ${(props) => props.theme.typography.weights.button.normal};
    }

    a {
      color: ${({ theme }) => theme.typography.anchor.default};
      text-decoration: ${({ theme }) => theme.typography.anchor.decoration};

      &:hover {
        color: ${({ theme }) => theme.typography.anchor.hover};
      }
    }

   p {
      line-height: ${({ theme }) => theme.typography.paragraph.lineHeight};
      margin-top: 0;
      margin-bottom: ${({ theme }) => theme.typography.paragraph.marginBottom};
      font-weight: ${({ theme }) => theme.typography.weights.primary.normal};
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-transform: ${({ theme }) => theme.typography.heading.textTransform};
      line-height: ${({ theme }) => theme.typography.heading.lineHeight};
      margin-top: 0;
      margin-bottom: ${({ theme }) => theme.typography.heading.marginBottom};
      font-family: ${({ theme }) => theme.typography.fonts.heading};
    }

    h1,
    h2 {
      font-weight: ${({ theme }) => theme.typography.weights.heading.normal}; 
    }

    h3,
    h4,
    h5,
    h6 {
      font-weight: ${({ theme }) => theme.typography.weights.heading.bold}; 
    }


    ${({ theme }) => headingSizes(theme.typography.heading.headingSizes)}

    table {
      th {
        text-align: left;
      }
    }

    img {
      display: inline-block;
      vertical-align: middle;
      max-width: 100%;
      height: auto;
    }

    figure {
      margin: 0;
    }

    hr {
      border-color: ${({ theme }) => theme.global.border.color};
      border-width: ${({ theme }) => theme.global.border.width};
      border-style: ${({ theme }) => theme.global.border.style};
      margin-top: ${rem(25)};
      margin-top: ${rem(25)};
    }

    label {
      display: block;
      line-height: ${({ theme }) => theme.typography.paragraph.lineHeight};
      margin: 0 0 0.15rem;
      font-weight: ${({ theme }) => theme.typography.weights.primary.bold};
      font-size: ${rem(15)};
      letter-spacing: 0.01em;
    }

    video,
    embed,
    iframe {
      max-width: 100%;
    }

    ul {
      font-family: ${({ theme }) => theme.typography.fonts.primary};
      font-weight: ${({ theme }) => theme.typography.weights.primary.normal};
    }

    textarea {
      min-height: ${rem(50)};
    }

    [type='text'],
    [type='password'],
    [type='date'],
    [type='datetime'],
    [type='datetime-local'],
    [type='month'],
    [type='week'],
    [type='email'],
    [type='number'],
    [type='search'],
    [type='tel'],
    [type='time'],
    [type='url'],
    [type='color'],
    textarea {
      display: block;
      appearance: none;
      width: 100%;
      box-sizing: border-box;
      box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
      font-size: 1rem;
      font-family: ${({ theme }) => theme.typography.fonts.alt};
      font-weight: ${({ theme }) => theme.typography.weights.alt.normal};
      line-height: 1.5;
      border: 1px solid ${(props) => props.theme.components.input.borderColor};
      padding: ${(props) => props.theme.components.input.padding};
      border-radius: ${(props) => props.theme.components.input.radius};
      margin-bottom: 0.5rem;
      color: ${(props) => props.theme.components.input.color};
      background-color: ${(props) =>
        props.theme.components.input.backgroundColor};
      transition: all ${(props) => props.theme.global.transition.duration} ${(
  props,
) => props.theme.global.border.timing};

      &::placeholder {
        color: #a7a7a7;
      }

      &:focus {
        outline: none;
        border-color: ${(props) => props.theme.palette.colors.primary};
        background-color: ${(props) =>
          props.theme.components.input.backgroundColorActive};
      }
    }

    select {
      width: 100%;
      height: 2.4375rem;
      margin: 0 0 1rem;
      padding: 0.5rem;
      appearance: none;
      border: 1px solid #cacaca;
      border-radius: 0;
      background-color: #fefefe;
      font-family: inherit;
      font-size: 1rem;
      font-weight: normal;
      line-height: 1.5;
      color: #0a0a0a;
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
      background-origin: content-box;
      background-position: right -1rem center;
      background-repeat: no-repeat;
      background-size: 9px 6px;
      padding-right: 1.5rem;
      transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
    }

    select:not([multiple]) {
      padding-top: 0;
      padding-bottom: 0;
    }

    button,
    [type="button"],
    [type="reset"],
    [type="submit"] {
      appearance: none;
      outline: 0;
    }

    [type="button"],
    input[type="button"],
    input[type="reset"],
    input[type="submit"] {
      background-color: ${({ theme }) => theme.palette.colors.primary};
      border: none;
      border-radius: ${({ theme }) => theme.components.button.radius};
      padding: ${({ theme }) => theme.components.button.padding};
      letter-spacing: ${({ theme }) => theme.typography.button.letterSpacing};
      text-transform: ${({ theme }) => theme.typography.button.textTransform};
      font-family: ${({ theme }) => theme.typography.fonts.button};
      font-weight: ${({ theme }) => theme.typography.button.fontWeight};
      margin-bottom: ${({ theme }) => theme.typography.paragraph.marginBottom};
      transition: all ${({ theme }) =>
        `${theme.global.transition.duration} ${theme.global.transition.timing}`};

      cursor: pointer;
      user-select: none;
      color: ${({ theme }) =>
        getContrast(theme.palette.colors.primary, '#fff') > 2.1
          ? '#fff'
          : '#000'};

      &:hover {
        background-color: ${({ theme }) =>
          lighten(
            theme.components.button.colorMultiplier,
            theme.palette.colors.primary,
          )};
        color: ${({ theme }) =>
          getContrast(theme.palette.colors.primary, '#fff') > 2.1
            ? '#fff'
            : '#000'};
      }

      &.expanded {
        width: 100%;
      }
    }

    [type=file],
    [type=checkbox],
    [type=radio] {
        margin: 0 0 1rem;
    }

    label > [type=checkbox],
    label>[type=radio] {
      margin-right: .5rem;
    }
  
    code {
      border-color: ${({ theme }) => theme.global.border.color};
      border-width: ${({ theme }) => theme.global.border.width};
      border-style: ${({ theme }) => theme.global.border.style};
      background-color: ${({ theme }) => theme.palette.colors.greyLight};
      display: inline;
      max-width: 100%;
      word-wrap: break-word;
      padding: 0.125rem 0.3125rem 0.0625rem;
    }

    pre {
      position: relative;
      margin: 0;
      overflow: auto;
      padding: 0;

      code {
        display: block;
        overflow-x: auto;
        padding: 1rem;
        padding-right: 4rem;
        background-color: ${({ theme }) => theme.palette.colors.greyLight};
        margin-bottom: 1.5rem;
      }
    }

    blockquote {
      position: relative;
      border: 0;
      font-size: ${rem(22)};
      width: 100%;
      text-align: center;
      margin: auto;
      font-family: ${(props) => props.theme.typography.fonts.alt};
      font-weight: ${(props) => props.theme.typography.weights.alt.normal};
      margin-top: ${rem(50)};
      margin-bottom: ${rem(50)};
      padding: ${rem(50)} 0 0 0;
    
      ${breakpoint('mediumlarge')} {
        padding-top: 0;
        padding-left: 2.7em;
        font-size: ${rem(23)};
      }

      ${breakpoint('large')} {
        font-size: ${rem(26)};
      }

      &:before {
        content: '”';
        display: block;
        line-height: 0;
        margin-top: 1rem;
        color: ${(props) => props.theme.palette.colors.primary};
        font-family: ${(props) => props.theme.typography.fonts.alt};
        font-weight: ${(props) => props.theme.typography.weights.alt.normal};
        font-size: 4em;

        ${breakpoint('mediumlarge')} {
          position: absolute;
          right: 100%;
          margin-top: 0;
          margin-right: -0.45em;
          top: 0.42em;
        }
      }
    }

    .ingress {
      font-size: ${rem(23)};
      font-family: ${(props) => props.theme.typography.fonts.alt};
      font-weight: ${(props) => props.theme.typography.weights.alt.normal};
      margin-bottom: 0.9rem;
      line-height: 1.45;

      ${breakpoint('mediumlarge')} {
        font-size: ${rem(27.6)};
      }
    }

    button[type=submit],
    .button {
      display: inline-block;
      cursor: pointer;
      border-radius: ${(props) => props.theme.components.button.radius};
      padding: ${(props) => props.theme.components.button.padding};
      appearance: none;
      user-select: none;
      font-size: ${(props) =>
        props.theme.typography.button.buttonSizes.default};
      font-family: ${(props) => props.theme.typography.fonts.button};
      font-weight: ${(props) => props.theme.typography.weights.button.bold};
      text-transform: ${(props) => props.theme.typography.button.textTransform};
      letter-spacing: ${(props) => props.theme.typography.button.letterSpacing};
      transition: ${(props) =>
        `all ${props.theme.global.transition.duration} ${props.theme.global.transition.timing}`};
      border: none;
      color: ${(props) => {
        const contrastRatio = getContrast(
          props.theme.palette.colors.primary,
          '#fff',
        );
        return contrastRatio > 3 ? '#fff' : '#000';
      }};
      background-color: ${(props) => props.theme.palette.colors.primary};

      /* cubic-bezier(0.78, 0.14, 0.15, 0.86) */

      &:hover {
        transform: scale(1.01);
        box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.2);
        background-color: ${(props) =>
          lighten(
            props.theme.components.button.colorMultiplier,
            props.theme.palette.colors.primary,
          )};
        color: ${(props) => {
          const contrastRatio = getContrast(
            props.theme.palette.colors.primary,
            '#fff',
          );
          return contrastRatio > 2.1 ? '#fff' : '#000';
        }};
      }

      ${buttonVariants('solid')}

      &:focus {
        outline: none;
      }

      &:disabled {
        background: gray;
      }
    }

    .ReactModal__Overlay {
      opacity: 0;
      will-change: opacity;
      transition: opacity 320ms cubic-bezier(0.78, 0.14, 0.15, 0.86);
    }
    
    .ReactModal__Overlay--after-open{
        opacity: 1;
    }
    
    .ReactModal__Overlay--before-close{
        opacity: 0;
    }

    body.template-package.sticky-bar #chat-widget-container { 
      bottom: 80px !important;
    }

    .videoWrapper {
      position: relative;
      display: block;
      height: 0;
      width: 100%;
      padding-bottom: 56.25%; /* 16:9 */
      margin-bottom: 2rem;

      object,
      embed,
      iframe,
      video {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
      }
    }
  `;
